<!-- 电子竞技 -->
<template>
    <div class="page-live swipers">
        <div class="page-live-tab">
           <div class="swipers">
                <swiper :options="swiperOption">
                    <swiper-slide  v-for="(item,index) in newList" :key="index">
                        <div class="live-tab">
                            <div class="item" :class="{'item-act':curId === citem.id}"
                            v-for="(citem,cindex) in item" :key="cindex" @click="switchTab(citem)">
                                {{citem.name}}
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
           </div>
            <!--以下看需要添加-->
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div> 
        </div>
        <div class="page-live-content">
            <div class="live-left" v-for="(item,index) in lists"  v-show="curId == item.id" :key="'img' + index">
                <img loading="lazy" v-lazy="item._imgUrl" class="animate__animated animate__bounceIn">
            </div>
            <div class="live-right">
                <div class="text1">{{ curItem.name }}</div>
                <div class="text2">{{ curItem.nameEn }}</div>
                <div class="text3">{{$t("gamePage.text13")}}</div>
                <div class="text4">{{$t("gamePage.text14")}}</div>
                <div>{{$t("gamePage.text15")}}</div>

                <div class="live-icon">
                    <div class="icon-box" v-for="(item,index) in iconList" :key="'icon' + index">
                        <img :src="item.img" alt="">
                        <div>{{ item.name }}</div>
                    </div>
                </div>
                <div class="buttom" @click="_getToken(curItem,2)">
                    {{$t("gamePage.text1")}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { _getToken } from '../../core/comp/common'
import { mapGetters } from 'vuex'


export default {
    data () {
        return {
            iconList:[
                {
                    name: this.$t("gamePage.text6"),
                    img:require('../../assets/images/game/black_esports_a001_game1.png')
                },
                {
                    name: this.$t("gamePage.text7"),
                    img:require('../../assets/images/game/black_esports_a001_game2.png')
                },
                {
                    name: this.$t("gamePage.text8"),
                    img:require('../../assets/images/game/black_esports_a001_game3.png')
                },
                {
                    name: this.$t("gamePage.text9"),
                    img:require('../../assets/images/game/black_esports_a001_game4.png')
                },
                {
                    name: this.$t("gamePage.text10"),
                    img:require('../../assets/images/game/black_esports_a001_game5.png')
                },
                {
                    name: this.$t("gamePage.text11"),
                    img:require('../../assets/images/game/black_esports_a001_game6.png')
                },
                {
                    name: this.$t("gamePage.text12"),
                    img:require('../../assets/images/game/black_esports_a001_game7.png')
                }
            ],
            curId: '',
            curItem:{},
            newList:[],// 分割之后的新数组
            swiperOption: {//swiper3
                // autoplay: 3000,
                speed: 1000,
                slidesPerView: 1,
                spaceBetween: 30,
                // loop:true,
                prevButton:'.swiper-button-prev',
                nextButton:'.swiper-button-next',
                pagination: '.swiper-pagination',
                paginationType:'fraction',
            },
        }
    },
    computed: {
        lists () { // 二级导航
            return this.gameMenu.arr_dj || []
        },
        ...mapGetters([
            'gameMenu', // 游戏分类
        ])
    },
    mounted() {
        this.splitList()
        this.curItem = this.lists[0]
        this.curId = this.lists[0].id
        
    },
    methods: {
        _getToken,
        switchTab(item) {
            this.curItem = item
            this.curId = item.id
        },
        // 数组切割 列表 每组几个
        splitList() {
            // let obj = this.lists.map( (item,index) => {
            //     return {...item,}
            // })
            for (let i = 0; i < this.lists.length; i += 5) {
                this.newList.push(this.lists.slice(i, i + 5));
            }
        },
    }
}
</script>

<style lang="less" scoped>
.page-live {
    background: url('../../assets/images/game/black_esports_a001_bg.jpg') center;
    background-size: 100% 100%;
}
@import url("../../assets/css/page-live.less");
</style>